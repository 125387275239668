
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'seedingTypeConfigDialog'
})
export default class extends Vue {
  @Prop() readonly seedingNoShow!: boolean

  private loading = false
  private tableData: any = []
  projectListAll: any = []
  currentRow: any = {}

  formatProjectListAll: any = []

  created () {
    this.getConfigList()
    this.getProjectAllByList()
  }

  getConfigList () {
    this.loading = true
    this.$axios.get(this.$apis.seedling.selectSeedlingCodeByList).then(res => {
      if (res) {
        this.tableData = (res || []).map((item: any) => {
          item.isEdit = false
          return item
        })
      }
    }).finally(() => {
      this.loading = false
    })
  }

  // 查询该用户下的所有能管理的项目列表
  getProjectAllByList () {
    this.$axios.get(this.$apis.project.selectProjectAllByList).then((res) => {
      this.projectListAll = res.list || []
    })
  }

  onAdd () {
    if (this.tableData.some((item: any) => item.isEdit)) {
      this.$message.warning('请先保存！')
    } else {
      const row = {
        codePrefix: '', // 编号前缀
        projectId: '', // 项目id
        codeSize: '', // 编号长度
        isEdit: true
      }
      this.tableData.push(row)
      this.currentRow = JSON.parse(JSON.stringify(row))
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  onEdit (row: any) {
    if (this.tableData.some((item: any) => item.isEdit)) {
      this.$message.warning('请先保存！')
    } else {
      this.currentRow = JSON.parse(JSON.stringify(row))
      row.isEdit = true
    }
  }

  onDelete (projectId: any) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.seedling.deleteDataSeedlingCode, { projectId }).then(() => {
        this.$message.success('删除成功')
        this.getConfigList()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onSave () {
    const row = this.currentRow
    if (!row.projectId) {
      this.$message({ message: '请选择项目', type: 'warning' })
      return
    }
    if (!row.codePrefix) {
      this.$message({ message: '请输入编号前缀', type: 'warning' })
      return
    }
    if (!/^[a-zA-Z]{4}$/.test(row.codePrefix)) {
      this.$message({ message: '编号前缀为4位大小写字母', type: 'warning' })
      return
    }
    if (!row.codeSize) {
      this.$message({ message: '请输入编号位数', type: 'warning' })
      return
    }
    if (!/^(6\d|[789]\d|\d)$/.test(row.codeSize)) {
      this.$message({ message: '编号位数至少为6位', type: 'warning' })
      return
    }
    const formData = JSON.parse(JSON.stringify(this.currentRow))
    delete formData.isEdit
    this.$axios.post(this.$apis.seedling.insertSeedlingCode, formData).then(() => {
      this.$message({
        message: '保存成功',
        type: 'success'
      })
      this.getConfigList()
    })
  }

  onCancel (row: any, index: number) {
    if (row.ctime) { // 表示是编辑
      row.isEdit = false
    } else {
      this.tableData.splice(index, 1)
    }
  }

  closeDialog () {
    this.$emit('closeDialog')
    this.$emit('update:seedingNoShow', false)
  }
}
